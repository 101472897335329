import gql from 'graphql-tag'

// USED IN: SCRIPT_MANAGER
export const CreateScript = gql`
  mutation CreateScript($organizationId: ID!, $userId: ID, $specialties: [SpecialtyType], $source: MacroSource) {
    createMacro(
      input: {
        organizationId: $organizationId
        userId: $userId
        tags: [{ name: { value: "New Script" }, type: MACRO_NAME }]
        specialties: $specialties,
        source: $source
      }
    ) {
      macro {
        id
        userId
        tags {
          type
          name {
            value
            aliases
          }
        }
        blocks {
          sectionId
          content
        }
        specialties
        source
      }
    }
  }
`

// USED IN: SCRIPT_MANAGER
export const GetScriptsByUserId = gql`
  query GetScriptsByUserId($organizationId: ID!, $userId: ID!, $specialties: [SpecialtyType]) {
    macros(input: { organizationId: $organizationId, userId: $userId, includeFields: true, specialties: $specialties }) {
      count
      results {
        id
        userId
        tags {
          type
          name {
            value
            aliases
          }
        }
        specialties
        source
      }
    }
  }
`

export const GetScriptById = gql`
  query GetScriptById($organizationId: ID!, $id: ID!) {
    macros(input: { organizationId: $organizationId, ids: [$id], includeFields: true }) {
      count
      results {
        id
        userId
        tags {
          type
          name {
            value
            aliases
          }
        }
        blocks {
          sectionId
          content
        }
        specialties
        source
      }
    }
  }
`

// USED IN: SCRIPT_MANAGER
export const GetScriptsByName = gql`
  query GetScriptsByName($organizationId: ID!, $name: String) {
    macros(input: { organizationId: $organizationId, name: $name }) {
      count
      results {
        id
        tags {
          type
          name {
            value
            aliases
          }
        }
      }
    }
  }
`

// USED IN: SCRIPT_MANAGER
export const UpdateScript = gql`
  mutation UpdateScript($organizationId: ID!, $id: ID!, $userId: ID, $tags: [TagInput!]!, $blocks: [BlockInput!]!, $specialties: [SpecialtyType!], $source: MacroSource) {
    updateMacro(
      input: {
        organizationId: $organizationId,
        macro: {
          id: $id,
          userId: $userId,
          tags: $tags,
          blocks: $blocks,
          specialties: $specialties,
          source: $source
        },
        preserveAliases: true
      }
    ) {
      macro {
        id
        userId
        tags {
          type
          name {
            value
            aliases
          }
        }
        blocks {
          sectionId
          content
        }
        specialties
        source
      }
    }
  }
`

// USED IN: COPY_TO_USER
export const CopyScript = gql`
  mutation CopyScript($organizationId: ID!, $id: ID!, $userId: ID) {
    copyMacro(input: { organizationId: $organizationId, id: $id, userId: $userId }) {
      macro {
        id
        userId
        tags {
          type
          name {
            value
            aliases
          }
        }
        blocks {
          sectionId
          content
        }
        specialties
        source
      }
    }
  }
`

// USED IN: SCRIPT_MANAGER
export const DeleteScript = gql`
  mutation DeleteScript($organizationId: ID!, $id: ID!) {
    deleteMacros(input: { organizationId: $organizationId, ids: [$id] }) {
      ids
    }
  }
`
