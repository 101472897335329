import React from 'react'
import { Mutation } from 'react-apollo'
import get from 'lodash.get'
import ReactTooltip from 'react-tooltip'
import { CopyNoteType, DeleteNoteTypes, GetNoteTypes, GetNoteTypeById } from '@sukiai/gql/admin'
import { ActionBar } from '../../components'
import { setError, notifySuccess, sortItemsByKey } from '../../lib/util'
import { FETCH_POLICY } from '../../lib/constants'

export default class NoteTypesActionBar extends React.Component {
  onCopy = async (cache, { data: { copyNoteType } }) => {
    const {
      organizationId,
      userId,
      setSelected,
      isOnboardingDefault,
      filteredSpecialty
    } = this.props

    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    // Get all current note types from the cache
    const { noteTypes: allNoteTypesRes } = cache.readQuery({
      query: GetNoteTypes,
      variables: { organizationId, userId }
    })

    // Add the copied note type to the cache
    const allNoteTypes = get(allNoteTypesRes, 'results', [])
    const copy = get(copyNoteType, 'noteType')

    // If this note type was copied from an org to a user,
    // remove the original org note type from the cache
    // since the user will now have access to their own instead
    if (get(copy, 'userId')) {
      // Finds the index of the org note type that the copy was made from
      // If this equals -1, it means the user copied one of their own note types
      // so we just push it to the end of the note types array
      const copiedOrgIdx = allNoteTypes.findIndex(nt => nt.name === copy.name && !nt.userId)
      if (copiedOrgIdx > -1) {
        allNoteTypes.splice(copiedOrgIdx, 1, copy)
      } else {
        allNoteTypes.push(copy)
      }
    } else {
      allNoteTypes.push(copy)
    }

    // Re-sort the note types by name
    sortItemsByKey(allNoteTypes, 'name')

    // Update the cache with the new list of note types
    await cache.writeQuery({
      query: GetNoteTypes,
      variables: { organizationId, userId },
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: get(allNoteTypes, 'length', 0),
          results: allNoteTypes
        }
      }
    })

    const copyId = get(copy, 'id')

    // Write the copied note type data to its corresponding query
    // This saves a trip to the server since we will want to display
    // this newly copied note type next
    await cache.writeQuery({
      query: GetNoteTypeById,
      variables: { organizationId, id: copyId },
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: 1,
          results: [copy]
        }
      }
    })

    // Set the selected note type to the copied one
    setSelected(copyId)
  }

  onDelete = async (cache, { data: { deleteNoteTypes } }) => {
    const {
      organizationId,
      userId,
      setSelected,
      isOnboardingDefault,
      filteredSpecialty
    } = this.props

    // Get all current note types from the cache
    // will need to query with selected specialty
    const queryVariables = { organizationId, userId }
    if (isOnboardingDefault) {
      queryVariables.specialties = [filteredSpecialty]
    }

    const { noteTypes: allNoteTypesRes } = cache.readQuery({
      query: GetNoteTypes,
      variables: queryVariables
    })

    // Remove the deleted note type from the cache
    const allNoteTypes = get(allNoteTypesRes, 'results', [])
    const deletedId = get(deleteNoteTypes, 'ids[0]')
    const deletedIdx = allNoteTypes.findIndex(nt => nt.id === deletedId)
    allNoteTypes.splice(deletedIdx, 1)

    // Update the cache with the new list of note types
    await cache.writeQuery({
      query: GetNoteTypes,
      variables: queryVariables,
      data: {
        noteTypes: {
          __typename: 'QueryNoteTypesPayload',
          count: get(allNoteTypes, 'length', 0),
          results: allNoteTypes
        }
      }
    })

    // Set the selected note type to the first one in the list
    // If there's a userId, try to find the first user note type
    // Will unselect all note types if there are none left
    let newId = get(allNoteTypes, '[0].id')

    if (userId) {
      const firstUserNoteType = allNoteTypes.find(nt => !!nt.userId)
      if (firstUserNoteType) newId = get(firstUserNoteType, 'id')
    }

    setSelected(newId)

    // Update the query result for this note type in the cache
    cache.writeQuery({
      query: GetNoteTypeById,
      variables: { organizationId, id: deletedId },
      data: { noteTypes: null }
    })
  }

  render () {
    const {
      organizationId,
      userId,
      noteTypeId,
      openCopyDialog,
      openSpecialtiesDialog,
      showSpecialties,
      readOnly,
      dirty,
      saveNoteType,
      isSubmitting
    } = this.props

    const copyVariables = { organizationId, userId, id: noteTypeId }
    const deleteVariables = { organizationId, ids: [noteTypeId] }

    const deleteRefetch = [
      {
        query: GetNoteTypes,
        variables: { organizationId, userId },
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY
      }
    ]

    const optimisticDelete = {
      __typename: 'Mutation',
      deleteNoteTypes: {
        __typename: 'DeleteNoteTypesPayload',
        ids: [noteTypeId]
      }
    }

    return (
      <Mutation
        mutation={CopyNoteType}
        variables={copyVariables}
        onError={setError}
        onCompleted={() => notifySuccess('Copied note type')}
        update={this.onCopy}
      >
        {(copyNoteType, { loading: copyLoading }) => (
          <Mutation
            mutation={DeleteNoteTypes}
            variables={deleteVariables}
            refetchQueries={deleteRefetch}
            optimisticResponse={optimisticDelete}
            onError={setError}
            onCompleted={() => notifySuccess('Deleted note type')}
            update={this.onDelete}
          >
            {(deleteNoteType, { loading: deleteLoading }) => {
              const disabled = !organizationId || !noteTypeId || isSubmitting || copyLoading || deleteLoading

              return (
                <ActionBar>
                  <ActionBar.Button
                    data-cy='delete-notetype'
                    onClick={deleteNoteType}
                    disabled={readOnly || disabled}
                    icon='delete'
                    data-tip='Delete note type'
                  />
                  {showSpecialties && (
                    <ActionBar.Button
                      data-cy='configure-notetype-specialties'
                      onClick={openSpecialtiesDialog}
                      disabled={disabled}
                      icon='school'
                      data-tip='Configure Specialties'
                    />
                  )}
                  <ActionBar.Button
                    data-cy='copy-notetype-to-user'
                    onClick={openCopyDialog}
                    disabled={disabled || dirty}
                    icon='person_add'
                    data-tip='Copy to another user'
                  />
                  <ActionBar.Button
                    data-cy='copy-notetype'
                    onClick={copyNoteType}
                    disabled={disabled || dirty}
                    icon='filter_none'
                    data-tip='Copy to this user'
                  />
                  <ActionBar.Button
                    data-cy='save-notetype'
                    onClick={saveNoteType}
                    disabled={!dirty || readOnly || disabled}
                    icon='check'
                    data-tip='Save note type'
                  />
                  <ReactTooltip place='left' type='dark' effect='solid' />
                </ActionBar>
              )
            }}
          </Mutation>
        )}
      </Mutation>
    )
  }
}
