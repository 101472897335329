import gql from 'graphql-tag'

// USED IN: NOTE_TYPES
// 09/10/2020 - emrNoteType will be null, when created, and needs to be setup on update
// for organizations where relevant (eg. Cerner/Epic EMR organizations)
export const CreateNoteType = gql`
  mutation CreateNoteType($organizationId: ID!, $noteType: NoteTypeInput!) {
    createNoteType(input: { organizationId: $organizationId, noteType: $noteType }) {
      noteType {
        id
        userId
        name
        keywords
        sections {
          sectionId: id
          name
          navigationKeywords
          content,
          pbcSectionFlag
        }
        destinations,
        specialties,
        emrNoteType {
          id
          code
          name
          codeSystem
        }
      }
    }
  }
`

// USED IN: NOTE_TYPES
export const GetNoteTypes = gql`
  query GetNoteTypes($organizationId: ID!, $userId: ID, $specialties: [SpecialtyType]) {
    noteTypes(input: { organizationId: $organizationId, userId: $userId, specialties: $specialties }) {
      count
      results {
        id
        name
        userId
        source
      }
    }
  }
`

// USED IN: NOTE_TYPE_EDITOR
export const GetNoteTypeById = gql`
  query GetNoteTypeById($organizationId: ID!, $id: ID!) {
    noteTypes(input: { organizationId: $organizationId, ids: [$id] }) {
      count
      results {
        id
        userId
        name
        keywords
        sections {
          sectionId: id
          name
          navigationKeywords
          content
          pbcSectionFlag
        }
        destinations,
        specialties,
        emrNoteType {
          id
          code,
          name,
          codeSystem,
          allowedSections {
            id,
            name
          },
          systemGenerated,
          specialtyType
        }
      }
    }
  }
`

// USED IN: NOTE_TYPE_EDITOR
export const UpdateNoteType = gql`
  mutation UpdateNoteType($organizationId: ID!, $noteType: NoteTypeInput!) {
    updateNoteType(input: { organizationId: $organizationId, noteType: $noteType }) {
      noteType {
        id
        userId
        name
        keywords
        sections {
          sectionId: id
          name
          navigationKeywords
          content,
          pbcSectionFlag
        }
        destinations,
        specialties,
        emrNoteType {
          id
          code,
          name,
          codeSystem,
          allowedSections {
            id,
            name
          },
          systemGenerated,
          specialtyType
        }
      }
    }
  }
`

// USED IN: ACTION_BAR
export const CopyNoteType = gql`
  mutation CopyNoteType($organizationId: ID!, $id: ID!, $userId: ID) {
    copyNoteType(input: { organizationId: $organizationId, id: $id, userId: $userId }) {
      noteType {
        id
        userId
        name
        keywords
        sections {
          sectionId: id
          name
          navigationKeywords
          content,
          pbcSectionFlag
        }
        destinations,
        specialties,
        emrNoteType {
          id
          code,
          name,
          codeSystem,
          allowedSections {
            id,
            name
          },
          systemGenerated,
          specialtyType
        }
      }
    }
  }
`

// USED IN: ACTION_BAR
export const DeleteNoteTypes = gql`
  mutation DeleteNoteTypes($organizationId: ID!, $ids: [ID!]!) {
    deleteNoteTypes(input: { organizationId: $organizationId, ids: $ids }) {
      ids
    }
  }
`
